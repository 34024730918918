import React from "react";
import { pricingpageCtaClick, trackEvent } from "../analytics";
import green from "../assets/green-check.png";
import red from "../assets/red-check.png";

function Plan({ price, name, info, popular, properties }) {
  return (
    <div className="flex flex-col m-[35px] md:m-0 md:w-[20rem] h-auto bg-white text-left p-8 rounded-lg md:rounded-none  md:first:rounded-l-lg md:last:rounded-r-lg border-[#DDE0E2]-1">
      <p className="text-2xl mt-[30px] font-semibold mb-4 ">{name}</p>
      <p className="mt-3">
        {price !== "Custom" && (
          <span className="font-bold text-[16px] mr-2 align-top">$</span>
        )}
        <span className="font-bold text-[48px] mr-2">{price}</span>
        {price === 0 && (
          <span className="bg-gray-200 font-medium p-2 rounded-md align-baseline">
            Free
          </span>
        )}
      </p>
      <p className="font-light mb-[46px] text-[16px] h-8 text-gray-500">
        {info}
      </p>
      <div className="mt-5 mb-2 text-[14px]">
        {properties.map(([text, color]) => (
          <div className="flex mb-[20px]" key={text}>
            <img
              src={color === "green" ? green : red}
              alt={color}
              className="mr-2 w-5 h-5"
            />
            <p className="font-light">{text}</p>
          </div>
        ))}
      </div>
      <br />
      {price === 0 ? (
        <button className="mt-auto bg-purple-700 text-white border-0 p-3 w-2/3 ml-auto mr-auto rounded focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 hover:bg-violet-800   dark:bg-violet-600 dark:hover:bg-violet-700 dark:focus:ring-violet-900" onClick={() => {
          trackEvent(pricingpageCtaClick, { button_id: "PlanSection", plan: price, button_text: "Request Trial" })
          window.open("/figma-plugin")
        }}>
          Request Trial
        </button>
      ) : (
        <button className="mt-auto border border-black bg-white text-black p-3 w-2/3 ml-auto mr-auto rounded focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 hover:bg-white-800   dark:bg-white dark:hover:bg-white-700 dark:focus:ring-white-900 disabled"
          onClick={() => {
            trackEvent(pricingpageCtaClick, { button_id: "PlanSection", plan: price, button_text: "Coming soon" })
            // window.open("https://calendly.com/copycat-demo/30-mins")
          }}>
          Coming soon
        </button>
      )}
    </div>
  );
}

export default Plan;
