import React from 'react'
import UsageTabs from './UsageTabs';
import developer from '../../../assets/product/developer.svg';
import designer from '../../../assets/product/designer.svg';
import productManager from '../../../assets/product/productManager.svg';
import detectiveCat from '../../../assets/product/detectiveCat.svg';

import developerImage from '../../../assets/product/developerImage.svg';
import cxoImage from '../../../assets/product/cxoImage.svg';
import designerImage from '../../../assets/product/designerImage.svg';
import productManagerImage from '../../../assets/product/productManagerImage.svg';

const tabs = [
    {title: 'Developers' , icon:developer , description:'Front-end and full-stack developers can use it daily in their workflow to generate component or layout code.',image: developerImage},
    {title: 'Designers' , icon:designer , description:'Designers can use CopyCat to Auto Layout their designs and make them responsive so that the designs are maintainable in the future. Additionally, they also see a sneak peek of the final UI.',image: designerImage},
    {title: 'Product Managers' , icon:productManager , description:'Ensure that all designs are developer-proofed before they go into development. Reduce the friction between the developer and designer thinking process. Speed up sprints.',image: productManagerImage},
    {title: 'CXOs' , icon:developer , description:'Save valuable dev time and thousands of dollars every year.',image: cxoImage},
];

function Usage() {
  return (
    <div className='py-[25px] px-[20px] md:py-[90px] md:px-[150px]'>
        <h1 className='text-[32px] md:text-[45px] text-center w-full md:w-[50%] mx-auto font-[700] leading-[42px] md:leading-[61px] mb-[20px] text-[#131313]'>Who is it for?</h1>
        <p className='text-[18px] text-center w-full md:w-[70%] mx-auto mb-[50px]'>CopyCat is best suited for businesses and dev agencies that uses Figma and React.js with at least one front-end developer and one designer in the team.</p>

        <UsageTabs tabs={tabs}/>

        <div className='bg-[#FEEC9C] py-[25px] md:py-[50px] px-[20px] md:px-[80px] relative w-full flex flex-col justify-center items-start mt-[135px] md:mt-[120px] rounded-[14px]'>
          <h1 className='text-[#000000] text-[24px] md:text-[38px] mb-[20px] font-[700]'>So, what are you waiting for?</h1>
          <p className='text-[#000000] text-[18px] md:text-[20px] mb-[20px] w-full md:w-[60%]'>Join the world’s best product teams scaling their U.I. development.</p>
          <button
            type="button"
            className="mt-10 border border-violet-700 focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 text-white bg-violet-700 hover:bg-violet-800   font-medium rounded-md text-sm px-6 py-2.5 mb-2 mr-2 dark:bg-violet-600 dark:hover:bg-violet-700 dark:focus:ring-violet-900"
          >
            Get Started
          </button>
          <img src={detectiveCat} alt="" className='absolute right-[0] md:right-[100px] bottom-[0]'/>
        </div>
    </div>
  )
}

export default Usage;