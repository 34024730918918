import { useSearchParams } from "react-router-dom";
import { trackEvent } from "../analytics";
import { BrandLogoSection } from "../components/BrandLogoSection";
import { CommentsSection } from "../components/CommentsSection";
import { ComponentLibrarySection } from "../components/ComponentLibrarySection";
import { ExplainerSecton } from "../components/ExplainerSection";
// import { GroupingSection } from "../components/GroupingSection";
import { HeroSecton } from "../components/HeroSection";
// import { MetricSecton } from "../components/MetricSection";
import { PreFooterSection } from "../components/PreFooterSection";
import { SecureSection } from "../components/SecureSection";
import { StatementSection } from "../components/StatementSection";
import { StepsSection } from "../components/StepsSection";
// import LinksSection from "../components/LinksSection";
import { ValueSection } from "../components/ValueSection";
import { VisionSection } from "../components/VisionSection";
import { landingpageVisit } from './../analytics';

export const Home = () => {
  const [searchParams,] = useSearchParams();
  searchParams.get("utm_source");
  trackEvent(landingpageVisit, { ...searchParams, page_title: "LandingPage" })
  return (
    <>
      <HeroSecton />
      <ExplainerSecton />
      {/* <LinksSection /> */}
      {/* <GroupingSection /> */}
      <BrandLogoSection />
      <div className="hidden md:block">
        <StatementSection />
      </div>

      <ValueSection />
      <StepsSection />
      <VisionSection />
      <div className="hidden md:block">
        <SecureSection /></div>

      <ComponentLibrarySection />
      <CommentsSection />
      <PreFooterSection />

    </>
  );
};
