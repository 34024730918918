import React from 'react'

function HeadSection({title,description}) {
  return (
    <>
      <h1 className='font-bold text-[32px] mb-[24px]'>{title}</h1>
      <p className='text-[#131313] border-b-[#DDE0E2] pb-[27px] border-b-2 mb-[35px]'>{description}</p>
    </>
  )
}

export default HeadSection