import { useState } from "react";
import image1 from "../assets/image 1.png";
import image2 from "../assets/image 2.png";
import image3 from "../assets/image 3.png";
import image4 from "../assets/image 4.png";
import image5 from "../assets/image 5.png";
import image6 from "../assets/image 6.png";
import image7 from "../assets/image 7.png";
import image8 from "../assets/image 8.png";
import image9 from "../assets/image 9.png";
import "./companyLogoAnimation.css";
import { SectionWrapper } from "./SectionWrapper";
import protrudingCat from "../assets/Protruding Cat.png";
const images = [
  image1,
  image2,
  image3,
  image7,
  image8,
  image9,
  image4,
  image5,
  image6,
];

const borderY0 = "border-y-0";
const borderX0 = "border-x-0";

export const ComponentLibrarySection = () => {
  const [selected, setSelected] = useState(-1)
  return (
    <SectionWrapper background={"bg-white"} justify="center" position={"relative overflow-clip"}>
      <div className="absolute bottom-5 -right-[140px] overflow-clip">
        <img
          alt="cat"
          className="protrudingCat"
          src={protrudingCat}
        />
      </div>
      <div className="flex flex-col w-fit">
        <div className="text-[20px] md:text-[38px] text-center font-bold text-gray-900 mb-[18px]">
          Works with your stack
        </div>
        <div className="text-[16px] md:text-[20px] text-center text-gray-900 my-[18px]">
          CopyCat works regardless of the component library, framework, or
          syntax.
        </div>
        <div className="grid gap-0 grid-rows-3 grid-flow-col mx-auto mt-[18px]">
          {images.map((image, index) => (
            <img
              onMouseEnter={() => setSelected(index)}
              onMouseLeave={() => setSelected(-1)}
              key={index}
              src={image}
              alt={"image" + index}
              // data-aos="fade-in"
              // data-aos-offset="150"
              // data-aos-duration="1000"
              className={`border 
              ${index > 2 && index < 6 ? "border-[2px]" : borderX0} 
              ${index % 3 === 1 ? "border-[2px]" : borderY0} 
              ${selected === -1 ? "" : selected !== index ? "opacity-60" : "shadow-lg"}`}
            />
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
};
