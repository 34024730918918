import { SectionWrapper } from "./SectionWrapper";
import tree from "../assets/tree.png";
import codePC from "../assets/code-pc.png";
import codeWrench from "../assets/code-wrench.png";
import pixelGrid from "../assets/pixel-grid.png";
const steps = [
  {
    image: codePC,
    text: "Smooth design to dev handoff",
  },
  {
    image: codeWrench,
    text: "Reduce design to dev back and forth",
  },
  {
    image: pixelGrid,
    text: "Developer-friendly boilerplate code",
  },
  {
    image: tree,
    text: "Integrate repo to reuse code",
  },
];

export const ValueSection = () => {
  return (
    <SectionWrapper background={"bg-violet-50"} justify="center">
      <div className="flex flex-col w-full justify-between">
        <div
          className="text-[20px] md:text-[38px] font-bold text-gray-900 text-center"
          
        >
          CopyCat helps teams of every <br /> size build UI faster

        </div>

        <div className="flex flex-wrap justify-evenly my-[18px]">
          {steps.map(({ image, text }, index) => (
            <ValuePoint image={image} text={text} key={index} index={index} />
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
};

const ValuePoint = ({ image, text, index }) => {
  return (
    <div
      className="my-[18px] flex flex-col flex-wrap w-1/2 md:w-1/5"
    >
      <div className="flex justify-center">
        <img src={image} alt={text} width={64} />
      </div>
      <div className="text-[16px] md:text-[24px] text-center">{text}</div>
    </div>
  );
};
