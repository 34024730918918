import React from 'react'
import Customers from './components/Customers';
import FeatureCard from './components/FeatureCard';
import ProductHead from './components/productHead';
import Usage from './components/Usage';
import friendlyBoilerplate from '../../assets/product/friendly-boilerplate.svg';
import searchableVariable from '../../assets/product/searchable-variable.svg';
import realtimePreview from '../../assets/product/realtime-preview.svg';
import componentMatch from '../../assets/product/component-match.svg';

const featureHighlights = [
  {
    title:'Production-friendly boilerplate', 
    image: friendlyBoilerplate,
    reverse: false,
    description: 'Reduce upfront effort',
    normalDeveloperDesc:'It takes 2-3 days to lay down the first version which is further subject to changes due to feedback.',
    copycatDeveloperDesc:'Copies production-ready boilerplate code at the start of the development sprint and avoids mundane coding tasks.' 
  },
  {
    title:'Searchable variable and class names', 
    image: searchableVariable,
    reverse: true,
    description: 'Minimum refactor involved',
    normalDeveloperDesc:'Refactoring somebody else’s code takes more time than writing from scratch.',
    copycatDeveloperDesc:'Searches for class names and variable names to easily make minor changes and push to production quickly.' 
  },
  {
    title:'Real-time preview', 
    image: realtimePreview,
    reverse: false,
    description: 'See the generated code in action on Figma',
    normalDeveloperDesc:'Writes code from scratch without knowing whether it will work.',
    copycatDeveloperDesc:'Instantly preview the generated code and copy only the parts that make sense.' 
  },
  {
    title:'Component Match and Layout Algorithms', 
    image: componentMatch,
    reverse: true,
    description: 'Developer proof designs',
    normalDeveloperDesc:'It is a real pain that designers don’t think like developers.',
    copycatDeveloperDesc:'Focuses on building gorgeous products and leaves the heavy lifting of developer-proofing designs to our algorithms.' 
  },
]

function Product() {
  return (
    <div className='pt-[60px] md:pt-[80px] overflow-clip'>
      <ProductHead/>

      <div className='w-full pt-[30px] md:pt-[130px]'>
        <h1 className='pb-[10px] md:pb-[30px] text-[32px] md:text-[38px] font-[700] mx-auto block text-center'>Feature Highlights</h1>
        <div className='flex flex-col'>
          {featureHighlights.map(feature => <FeatureCard key={feature.title} image={feature.image} title={feature.title} description={feature.description}  normalDeveloperDesc={feature.normalDeveloperDesc} copycatDeveloperDesc={feature.copycatDeveloperDesc} reverse={feature.reverse}/> )}
            
        </div>
      </div>
      <Customers />
      <Usage />

    </div>
  )
}

export default Product