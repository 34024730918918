import React from "react";
import { SectionWrapper } from "./SectionWrapper";
import features from "./features.json";
import featuresData from "./featuresData.json";

function FeaturesSection() {
  return (
    <SectionWrapper
      background={"bg-gradient-to-b from-zinc-50 to-white"}
      justify="center"
    >
      <div className="flex flex-col lg:w-2/3">
        <p className="text-[20px] md:text-[38px] font-bold text-center">Feature comparision</p>
        <p className="mt-4 mb-[30px] text-center">
          Learn about differences between plans.
        </p>
        <div className="flex justify-between">
          <div className="flex flex-col md:w-1/2 mr-7 md:py-[32px]">
            <p className="w-full h-[3rem]"></p>
            {features.map((feature) => (
              <>
                <p
                  className="flex font-semibold h-[3rem] items-center"
                  key={feature.label}
                >
                  {feature.label}
                </p>
                {feature.values.map((value) => (
                  <p className="w-full h-[3rem]" key={value}>
                    {value}
                  </p>
                ))}
              </>
            ))}
          </div>
          {featuresData.map((featureData, index) => (
            <div
              className={`flex flex-col md:p-[32px] md:w-1/3 justify-center border-2 border-r-0 ${
                index === 0 && "rounded-l-lg"
              } last:border-r-2 last:rounded-r-lg`}
              key={featureData.label}
            >
              <div className="flex-col h-[3rem]">
              <div className="font-bold text-center">{featureData.label}</div>
                <div className="text-sm text-center">{featureData.subText}</div>
              </div>
              {featureData.values.map((value, index) => (
                <p className="flex h-[3rem] justify-center" key={index}>
                  {value === "0" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#d63031"
                    >
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                  {value === "1" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="#37b26c"
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  )}
                  {value !== "null" && value !== "0" && value !== "1" && (
                    <>{value}</>
                  )}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
}

export default FeaturesSection;
