/* eslint-disable react/no-unescaped-entities */
import stopSign from "../assets/stop-cat.png";
import cat from "../assets/cat-4x.png";
import { SectionWrapper } from "./SectionWrapper";
import FigmaLogo from "../assets/figma-logo.png";

import "./customAnimations.css";
import { landingpageCtaClick, trackEvent } from "../analytics";
export const StatementSection = () => {
  return (
    <SectionWrapper
      background={"bg-white"}
      justify={"center"}
      position={"relative"}
    >
      <div className="absolute bottom-0 left-12 overflow-clip">
        <img
          alt="cat"
          className="translate-y-[100px] hover:translate-y-0 duration-500 ease-out"
          src={cat}
        />
      </div>
      <div className="absolute left-[50%] border-opacity-50 border-dashed border-zinc-800 border-r-[2px] h-[100%]">
        
      </div>
      <div className="flex flex-col text-center md:w-1/2 z-10">
        <div className="text-[24px] font-medium">
          <div className="text-black my-[24px] bg-white">
            Are you doing a lot of mundane coding tasks?
          </div>
          <div className="text-black my-[24px] bg-white">
            Do you spend hours writing components, fixing 'minor' bugs and
            responsive issues?
          </div>
          <div className="text-black my-[24px] bg-white">
            Do you feel like your time could be invested in something more
            valuable?
          </div>
          <div className="flex justify-center my-[24px] bg-white">
            <img src={stopSign} alt={"stopSign"} width={177} />
          </div>
          <div className="text-violet-500 my-[24px] bg-white">
            Start building awesome UI in less time.
          </div>
          <div className="text-violet-500 my-[24px] bg-white">
            Invest more time in building stunning interactions, animations, and
            networking.
          </div>
          <div className="text-violet-500 my-[24px] bg-white">
            Build gorgeous UI faster than anybody else.
          </div>
          <div className="text-black mt-[24px] mb-[48px] bg-white">
            Build World-class UI in half the time
          </div>
          <div className="flex justify-center mx-auto">
            <div className="relative bottom-4 z-10 rounded-[50px] w-[35px] border-violet-500 border-2 bg-white h-[35px] px-[11px] py-[10px]">
              <img src={FigmaLogo} alt="" />
            </div>
            <button
              onClick={() => {
                trackEvent(landingpageCtaClick, { button_id: "StatementSection",button_text:" Get Started" })
                window.open("/figma-plugin")
              }}
              type="button"
              className="relative left-[-15px] border border-violet-700 focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 text-white bg-violet-700 hover:bg-violet-800   font-medium rounded-md text-sm px-6 py-2.5 mb-2 mr-2 dark:bg-violet-600 dark:hover:bg-violet-700 dark:focus:ring-violet-900"
            >
              Get Started
            </button>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};
