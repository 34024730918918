import { useSearchParams } from "react-router-dom";
import { pricingpageVisit, trackEvent } from "../analytics";
import AdditionalSection from "../components/AdditionalSection";
import FeaturesSection from "../components/FeaturesSection";
import PlansSection from "../components/PlansSection";
import FAQSection from "./../components/FAQSection";

export const Pricing = () => {
  const [searchParams,] = useSearchParams();
  searchParams.get("utm_source");
  trackEvent(pricingpageVisit, { ...searchParams, page_title: "PricingPage" })

  return (
    <>
      <PlansSection />
      <div className="hidden md:block"><FeaturesSection /></div>
      <FAQSection />
      <div className="hidden md:block"><AdditionalSection /></div>
    </>
  );
};
