import React from 'react'
import { Outlet } from 'react-router-dom'
import DashboardSidebar from '../../components/DashboardSidebar'


function DashboardLayout() {
  return (
    <div className='dashboard-layout pt-[65px] md:pt-[80px] flex flex-row w-full min-h-[100vh] md:min-h-[100vh] overflow-hidden'>
        <DashboardSidebar />
        
        <div className=" md:relative dashboard-content__container w-[100%] md:w-[82%] pt-[24px] px-[20px] md:px-[100px]">
            {
              <Outlet />
            }
        </div>
    </div>
  )
}

export default DashboardLayout