import React from 'react';
import HeadSection from '../../../components/HeadSection';
import componentCode from '../../../assets/dashboardPages/componentCode.svg';
import componentPreview from '../../../assets/dashboardPages/componentPreview.svg';

function ComponentsButtons() {
  return (
    <div className='designs-home__container'>
     <HeadSection title='Buttons' description='Generate production-grade code from Figma designs. Build stunning apps.'/>

     <h3 className='font-bold text-[21px] mb-[20px]'>1. Primary Button</h3>
     <p className='text-[#131313] mb-[50px]'>Generate production-grade code from Figma designs. Build stunning apps.</p>

    <div className='w-full flex flex-col md:flex-row'>
      <div className='flex flex-col items-start w-[100%] mb-[10px] md:mb-[0] md:w-[50%]'>
        <h1 className='text-[21px] md:text-[16px] mb-[10px] md:mb-[32px]'>component Code</h1>
        <img className='w-full' src={componentCode} alt="" />
      </div>
      <div className='flex flex-col items-start w-[100%] mb-[10px] md:mb-[0] md:w-[50%]'>
        <h1 className='text-[21px] md:text-[16px] mb-[10px] md:mb-[32px]'>component Preview</h1>
        <img className='w-full' src={componentPreview} alt="" />
      </div>
    </div>
    </div>
  )
}

export default ComponentsButtons