import { SectionWrapper } from "./SectionWrapper";
import M15 from "../assets/15M.png";
import K5 from "../assets/5.1k.png";
import K320 from "../assets/320k.png";
import K480 from "../assets/480k.png";
export const ExplainerSecton = () => {
  return (
    <SectionWrapper background={"bg-white"} justify={"center"}>
      <div className="flex flex-col min-w-fit lg:w-[75%]">
        <div
          className="text-[20px] md:text-[38px] font-bold text-gray-900 text-center"
          data-aos="fade-up"
        >
          Save hours of valuable dev time using CopyCat
        </div>
        <div className="flex flex-wrap justify-center py-[30px] md:py-[70px]">
          <iframe
            className=" shadow-md w-full md:w-[770px] md:h-[480px]"

            src="https://www.youtube.com/embed/Z50x1GgJwUk"
            title="CopyCat - Build UI Faster Than Your Competition"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="flex justify-between flex-wrap md:flex-row">
          <div className="w-1/2 md:w-fit text-4xl font-bold text-gray-900 text-center">
            <img src={K5} alt="K5" className="mx-auto" />
            <span className="text-[16px] md:text-[24px] font-medium mt-[30px]">Developers</span>
          </div>
          <div className="w-1/2 md:w-fit text-4xl font-bold text-gray-900 text-center">
            <img src={K320} alt="K5" className="mx-auto" />
            <span className="text-[16px] md:text-[24px] font-medium mt-[30px]">
              Designs converted
            </span>
          </div>
          <div className="w-1/2 md:w-fit text-4xl font-bold text-gray-900 text-center">
            <img src={M15} alt="K5" className="mx-auto" />
            <span className="text-[16px] md:text-[24px] font-medium mt-[30px]">
              Lines generated
            </span>
          </div>
          <div className="w-1/2 md:w-fit text-4xl font-bold text-gray-900 text-center">
            <img src={K480} alt="K5" className="mx-auto" />
            <span className="text-[16px] md:text-[24px] font-medium mt-[30px]">Hours saved</span>
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};
