import React from "react";
import Plan from "./Plan";
import { SectionWrapper } from "./SectionWrapper";

const PlanDetails = [
  {
    info: "Upgraded to individual plan after trial",
    detail: "Join Slack community for access",
    price: 0,
    name: "Community Edition",
    properties: [
      ["7 days with unlimited exports", "green"],
      //["2 Figma files ", "green"],
      ["Slack community & support", "green"],
      ["TypeScript support", "green"],
      ["Edit code in plugin", "green"],
      ["Preview generated/edited code", "green"],
      ["Components and assets export", "green"],
      ["Layout code", "green"],
    ],
  },
  {
    info: "Best for new projects, freelancers, and agencies",
    detail: "Best for individual coders and new projects",
    price: 49,
    name: "Pro",
    popular: "most",
    properties: [
      ["Everything in Basic", "green"],
      ["Unlimited exports", "green"],
      ["Import CSS styles", "green"],
      ["Unlimited Figma files", "green"],
      ["Priority Slack support", "green"],
    ],
  },
  {
    info: "Best for medium to large teams with Github repo",
    detail: "For medium to large sized teams with mature codebases",
    price: "Custom",
    name: "Team",
    properties: [
      ["Everything in Pro", "green"],
      ["Integrate with existing component libraries", "green"],
      ["GitHub integration for custom components", "green"],
      ["Autolayout from designs", "green"],
      ["Responsive UI", "green"],
      ["Dedicated account executive", "green"],
      ["Data retention exemption", "green"],
      ["Team management", "green"],
      ["and more…", "green"],
    ],
  },
];

function PlansSection() {
  return (
    <SectionWrapper background={"bg-gradient-to-b from-purple-50 via-zinc-100 to-zinc-50"}>
      <div className="flex flex-col">
        <div className="text-neutral-900 py-10 text-center">
          <p className="text-[24px] md:text-[48px] font-bold">
            Simple pricing plans for everyone
          </p>
          <p className="mt-5 text-[16px] md:text-[20px]">
            Start building faster with any of our pricing plans. We will
            <br />
            return your money if you are not happy with CopyCat.
          </p>
        </div>
      </div>
      <div className="flex-wrap mx-auto md:flex justify-center divide-y divide-x md:divide-y-0 divide-gray shadow-md">
        {PlanDetails.map((plan) => (
          <Plan
            key={plan.name}
            info={plan.info}
            detail={plan.detail}
            price={plan.price}
            name={plan.name}
            popular={plan.popular}
            properties={plan.properties}
          />
        ))}
      </div>

    </SectionWrapper>
  );
}

export default PlansSection;
