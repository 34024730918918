import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import Dropdown from './common/Dropdown'
import arrowRight from '../assets/arrow.svg';

const dropdownItems = [
    {
        dropdownName: 'Design Files',
        children: [
            {name:'Home Page',path:'/dashboard/designs/home'},
            {name:'Product Page',path:'/dashboard/designs/product'},
            {name:'Pricing Page',path:'/dashboard/designs/pricing'},
        ]
    },
    {
        dropdownName: 'Components',
        children: [
            {name:'Buttons',path:'/dashboard/components/buttons'},
            {name:'Nav Bar',path:'/dashboard/components/navbar'},
            {name:'Footer',path:'/dashboard/components/footer'},
        ]
    },
    {
        dropdownName: 'Layout Code',
        children: [
            {name:'Home Page',path:'/dashboard/layout/home'},
            {name:'Product Page',path:'/dashboard/layout/product'},
            {name:'Pricing Page',path:'/dashboard/layout/pricing'},
        ]
    },
]


function DashboardSidebar() {
const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const activeLinkStyle = (status) => {
        return ({
        background: status.isActive ? '#F9F6FF' : 'transparent' ,
        color:status.isActive ? '#6930CA' : '#000000',
        width:'100%',
        display:'block',
        padding: '10px 32px'
    })};

    const toggleSidebar = () =>{
        setIsSidebarOpen(isOpen => !isOpen);
    }

  return (
    <div className={`z-10 dashboard-sidebar min-h-[100vh] w-[80%] md:w-[18%] ${isSidebarOpen ? 'translate-x-[0]' : 'translate-x-[-100%] '} absolute md:relative flex flex-col bg-[#FFFFFF] border-r-[1px] border-r-[#DDE0E2] shadow-sm py-[24px] md:translate-x-[0]`}>
        <div className='close-btn bg-[#ffffff] md:hidden w-fit border-[1px] px-[6px] py-[6px] absolute right-[-31px] top-[0] border-[#DDE0E2] shadow-sm rounded-tr-[20px] rounded-br-[20px] ' onClick={toggleSidebar}><img src={arrowRight} alt="" className='w-[17px] h-[17px]'/></div>
        <h1 className='px-[32px] pb-[10px] font-bold text-[24px]'>Heading</h1>
        <ul>
            {dropdownItems.map(item => {
                return <Dropdown children={item.children} dropdownName={item.dropdownName}/>
            })}
            <li className='w-full mb-[.3rem]'><NavLink style={activeLinkStyle} to={'/dashboard/stack'}>Stack</NavLink></li>
            <li className='w-full mb-[.3rem]'><NavLink style={activeLinkStyle} to={'/dashboard/assets'} >Assets</NavLink></li>
        </ul>
    </div>
  )
}

export default DashboardSidebar