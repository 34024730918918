import React from 'react'
import HeadSection from '../../../components/HeadSection'
import layoutCode from '../../../assets/dashboardPages/layoutCode.svg';

function LayoutCodesHome() {
  return (
    <div className='layoutCodes-home__container'>
     <HeadSection title='Home Page' description='Generate production-grade code from Figma designs. Build stunning apps'/>

     <h3 className='font-bold text-[21px] mb-[32px]'>Layout Code</h3>

    <div className='w-full'>
      <img src={layoutCode} className='w-full' alt=""/>
    </div>
    </div>
  )
}

export default LayoutCodesHome