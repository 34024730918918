import { SectionWrapper } from "./SectionWrapper";
import logo from "../assets/cat-withback.png";

const footerLinks = [
  [
    {
      header: "Integrations",
    },
    {
      label: "Figma to HTML,CSS",
      href: "/figma-html-css",
    },
    {
      label: "Figma to Tailwind",
      href: "/figma-tailwind-css",
    },
    {
      label: "Figma to Bootstrap",
      href: "/figma-to-bootstrap",
    },
    {
      label: "Figma to Ant Design",
      href: "/figma-to-ant",
    },
    {
      label: "Figma to Material",
      href: "/figma-to-mui",
    },
    {
      label: "Figma to Chakra",
      href: "/figma-to-chakra",
    },
  ],
  [
    {
      header: "Resources",
    },
    {
      label: "Pricing",
      href: "/pricing2",
    },
    {
      label: "Documentation",
      href: "https://www.notion.so/copycatdev/Documentation-live-3ff9652da99d4603bf7ab6f18c72e564",
      target: "_blank"
    },
    {
      label: "About Us",
      href: "/company/about",
    },
  ],
  [
    {
      header: "Blogs",
    },
    {
      label: "React Best Practices",
      href: "/blog/react-best-practices/",
    },
    {
      label: "React.js Conditional Rendering",
      href: "/blog/react-js-conditional-rendering/",
    },
    {
      label: "Design System Examples",
      href: "/blog/design-system-examples/",
    },
    {
      label: "React Spring: Walk throughs and Examples",
      href: "/blog/react-spring/",
    },
    {
      label: "Top 13 React Chrome Extensions",
      href: "/blog/react-chrome-extension/",
    },
  ],
];

export const Footer = () => {
  return (
    <SectionWrapper background={"bg-stone-900"} justify="center">
      <div className="flex flex-wrap justify-between md:w-2/3 px-[24px]">
        {footerLinks.map((linkGroup, index) => (
          <div
            className="flex flex-col text-left md:w-1/4  mb-8 md:first:border-r-[0.5px] border-zinc-600"
            key={index}
          >
            {linkGroup.map((link) => (

              link.header ?
                <div key={link.header} className="mb-4 text-gray-50 text-sm md:text-[20px] font-bold">{link.header}</div>
                :
                <div className="mb-4">
                  <a key={link.label} {...link} className="text-zinc-500 text-xs md:text-[16px] hover:text-white">
                    {link.label}
                  </a>
                </div>

            ))}
          </div>
        ))}
      </div>
      <div className="flex flex-col p-6 text-gray-50 w-full">
        <div className="flex justify-center p-2 ">
          <a
            href="https://twitter.com/copycatdotdev"
            target="_blank"
            className="mr-6 text-gray-50" rel="noreferrer"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="twitter"
              className="w-4"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
              ></path>
            </svg>
          </a>

          <a
            href="https://www.linkedin.com/company/copycat-dev"
            target="_blank"
            className="mr-6 text-gray-50" rel="noreferrer"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="linkedin-in"
              className="w-3.5"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
              ></path>
            </svg>
          </a>
          <a
            href="https://join.slack.com/t/copycat-community/shared_invite/zt-1dyboadba-BhqCaKiOlmKM~~wimunHmg"
            target="_blank"
            className="text-gray-50" rel="noreferrer"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="linkedin-in"
              className="w-3.5"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 2447.6 2452.5"
            >
              <g clipRule="evenodd" fillRule="evenodd">
                <path
                  d="m897.4 0c-135.3.1-244.8 109.9-244.7 245.2-.1 135.3 109.5 245.1 244.8 245.2h244.8v-245.1c.1-135.3-109.5-245.1-244.9-245.3.1 0 .1 0 0 0m0 654h-652.6c-135.3.1-244.9 109.9-244.8 245.2-.2 135.3 109.4 245.1 244.7 245.3h652.7c135.3-.1 244.9-109.9 244.8-245.2.1-135.4-109.5-245.2-244.8-245.3z"
                  fill="#ffffff"
                />
                <path
                  d="m2447.6 899.2c.1-135.3-109.5-245.1-244.8-245.2-135.3.1-244.9 109.9-244.8 245.2v245.3h244.8c135.3-.1 244.9-109.9 244.8-245.3zm-652.7 0v-654c.1-135.2-109.4-245-244.7-245.2-135.3.1-244.9 109.9-244.8 245.2v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.3z"
                  fill="#2eb67d"
                />
                <path
                  d="m1550.1 2452.5c135.3-.1 244.9-109.9 244.8-245.2.1-135.3-109.5-245.1-244.8-245.2h-244.8v245.2c-.1 135.2 109.5 245 244.8 245.2zm0-654.1h652.7c135.3-.1 244.9-109.9 244.8-245.2.2-135.3-109.4-245.1-244.7-245.3h-652.7c-135.3.1-244.9 109.9-244.8 245.2-.1 135.4 109.4 245.2 244.7 245.3z"
                  fill="#ecb22e"
                />
                <path
                  d="m0 1553.2c-.1 135.3 109.5 245.1 244.8 245.2 135.3-.1 244.9-109.9 244.8-245.2v-245.2h-244.8c-135.3.1-244.9 109.9-244.8 245.2zm652.7 0v654c-.2 135.3 109.4 245.1 244.7 245.3 135.3-.1 244.9-109.9 244.8-245.2v-653.9c.2-135.3-109.4-245.1-244.7-245.3-135.4 0-244.9 109.8-244.8 245.1 0 0 0 .1 0 0"
                  fill="#e01e5a"
                />
              </g>
            </svg>
          </a>
        </div>
        <br />
        <a href="/" className="flex justify-center items-center">
          <img src={logo} className="mr-3 h-6 sm:h-9" alt="Copycat Logo" />
        </a>
        <br />
        <a className="text-gray-50 text-center p-2" href="#!">
          Copycat © 2021 - 2022 | <a href="https://www.termsfeed.com/live/29eaf2bb-38dd-47e9-a8ff-95aac6369280">Terms of use</a> | <a href="https://www.termsfeed.com/live/ca85916c-44fc-4b09-aaf2-f6af8c2f0bd9">Privacy Policy</a>
        </a>
        <div className="flex flex-row justify-center">
          <p>
            Made with <span className="text-red-600">&#10084;</span> using
            CopyCat
          </p>
        </div>
      </div>
    </SectionWrapper >
  );
};
