import { SectionWrapper } from "./SectionWrapper";
import cat from "../assets/detective-cat.png";
import { useState } from "react";
import { landingpageCtaClick, trackEvent } from "../analytics";

export const PreFooterSection = () => {
  const [isMouseEnter, setisMouseEnter] = useState(false);
  return (
    <SectionWrapper background={"bg-white"}>
      <div
        className="bg-amber-200 relative flex flex-wrap justify-between p-[24px] md:pt-[50px] md:px-[80px] rounded-[15px] md:w-3/4 overflow-clip"
        onMouseEnter={() => setisMouseEnter(true)}
        onMouseLeave={() => setisMouseEnter(false)}
      >
        <div>
          <p className="font-bold text-[24px] md:text-[38px]">So, What are you waiting for?</p>
          <p className="font-medium text-[16px] md:text-[20px] mt-2">
            Join the world’s best product teams scaling their U.I. development.
          </p>
          <button
          onClick={()=>{
            trackEvent(landingpageCtaClick,{button_id:"PreFooterSection",button_text:" Get Started"})
            window.open("/figma-plugin")
          }}
            type="button"
            className="mt-10 border border-violet-700 focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 text-white bg-violet-700 hover:bg-violet-800   font-medium rounded-md text-sm px-6 py-2.5 mb-2 mr-2 dark:bg-violet-600 dark:hover:bg-violet-700 dark:focus:ring-violet-900"
          >
            Get Started
          </button>
        </div>
        <div className="hidden md:flex -mt-[250px] ml-[75%]">
          <img
            src={cat}
            alt={cat}
            className={`${
              isMouseEnter ? "translate-y-[30%]" : "translate-y-[50%]"
            } duration-300 ease-in md:h-[270px] h-[140px]`}
          />
        </div>
        <div className="md:hidden absolute bottom-3 -right-14">
          <img
            src={cat}
            alt={cat}
            className={`${
              isMouseEnter ? "translate-y-[30%]" : "translate-y-[60%]"
            } duration-300 ease-in md:h-[270px] h-[140px]`}
          />
        </div>
      </div>
    </SectionWrapper>
  );
};
