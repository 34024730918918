import React from 'react'
import catWithBack from '../../../assets/cat-withback.png';

function FeatureCard({reverse = false,title,description,normalDeveloperDesc,copycatDeveloperDesc,image}) {
  return (
    <div className={`w-full flex items-center px-[20px] md:px-[100px] py-[25px] md:py-[120px] ${reverse ? 'flex-col-reverse md:flex-row-reverse' : 'flex-col md:flex-row'}`}>
        <div className='w-full md:w-[50%] h-[300px]  md:h-[450px] rounded-[8px] my-[20px] md:my-[0] border-[#dddddd] border-[1px] overflow-hidden'>
            <img src={image} alt="" className='w-full h-full'/>
        </div>
        <div className='w-full md:w-[50%] px-[0px] md:px-[60px]'>
        <h5 className='text-[32px] md:text-[45px] font-[700] leading-[42px] md:leading-[61px] mb-[20px] text-[#000000] mt-[0] md:mt-[20px] md:mt-[0]'>{title}</h5>
        <p className='text-[18px] md:text-[21px] mb-[45px] md:mb-[40px]'>{description}</p>

        <div className='relative px-[20px] pt-[30px] pb-[20px] border-[#292D3233] border-[1px] rounded-[4px] mb-[40px]'>
            <div className='absolute top-[-14%] py-[5px] px-[10px] rounded-[2px] left-[20px] bg-[#E3E7EB] border-[#00000033] border-[1px]'>
                <span className='text-[#292D32] text-[14px]'>NORMAL DEVELOPER</span>
            </div>
            <p className='text-[#131313] text-[18px]'>{normalDeveloperDesc}</p>
        </div>

        <div className='relative px-[20px] pt-[30px] pb-[20px] border-[#292D3233] border-[1px] rounded-[4px] mb-[0] md:mb-[40px]'>

            <div className='absolute top-[-16px] md:top-[-14%]  rounded-[2px] left-[20px] bg-[#FFF9DD] border-[#00000066] border-[1px] flex items-center'>
                <span className='text-[#292D32] text-[14px] py-[5px] px-[10px]'>DEVELOPER USING COPYCAT</span>
                <div className='w-[40px] h-[31px] bg-[#fDD009] border-l-[#00000066] border-l-[1px]'>
                <img src={catWithBack} alt="" className='w-full h-full'/>
                </div>
            </div>
            <p className='text-[#131313] text-[18px]'>{copycatDeveloperDesc}</p>
        </div>
        </div>
    </div>
  )
}

export default FeatureCard