import FigmaToLogo from "../assets/figma-to-react.png";
import ReactIcon from "../assets/react.png";
import { SectionWrapper } from "./SectionWrapper";
import FigmaLogo from "../assets/figma-logo.png";

import "./customAnimations.css";
import { landingpageCtaClick, trackEvent } from "../analytics";
export const HeroSecton = () => {
  return (
    <SectionWrapper background={"bg-purple-50 pt-[80px] overflow-clip"} justify={"center"} position={"relative"}>
      <img src={ReactIcon} alt="react" className="absolute left-[100px] -top-[10px]" />
      <img src={ReactIcon} alt="react" className="absolute right-[100px] -bottom-[90px]" />
      <div className="flex flex-col text-center">
        <div className="text-[24px] md:text-[48px] font-bold text-gray-900" data-aos="fade-up">
          Your <span className="text-violet-700">React.js</span> Co-pilot
        </div>
        <div
          className="text-[16px] md:text-[20px] my-[24px] text-gray-900"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          Generate production-friendly code from Figma designs.
          <br className="hidden md:block" /> Build stunning apps and web pages faster.
        </div>
        <div className="my-[36px] flex flex-col justify-center">
          <img
            className="mx-auto"
            src={FigmaToLogo}
            alt="figma-to-react"
            data-aos="fade-up"
            data-aos-duration="1000"
            width={240}
          />
        </div>
        <div
          className="flex flex-col md:flex-row md:mx-auto mx-[75px]"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="hidden md:block relative bottom-4 z-10 rounded-[50px] w-[35px] border-violet-500 border-2 bg-white h-[35px] px-[11px] py-[10px]">
            <img src={FigmaLogo} alt="" />
          </div>
          <button
            onClick={() => {
              trackEvent(landingpageCtaClick, { button_id: "HeroSection", button_text: " Get Started" })
              window.open("/figma-plugin")
            }}
            type="button"
            className="relative border border-violet-700 focus:outline-none md:left-[-15px]  shadow-2d hover:shadow-1d shadow-black-700/50 text-white bg-violet-700 hover:bg-violet-800   font-medium rounded-md text-sm px-6 py-2.5 mb-4 mr-2 dark:bg-violet-600 dark:hover:bg-violet-700 dark:focus:ring-violet-900"
          >
            Get Started
          </button>
          <button
            onClick={() => {
              trackEvent(landingpageCtaClick, { button_id: "HeroSection", button_text: "Join Slack" })
              window.open("https://join.slack.com/t/copycat-community/shared_invite/zt-1dyboadba-BhqCaKiOlmKM~~wimunHmg", "_blank")
            }}
            type="button"
            className="border border-black focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 text-black bg-white hover:bg-white-800  font-medium rounded-md text-sm px-6 py-2.5 mb-4 mr-2 dark:bg-white dark:hover:bg-white"
          >
            Join Slack
          </button>
        </div>
      </div>
    </SectionWrapper>
  );
};
