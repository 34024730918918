import React from 'react'
import tailwind from '../../assets/dashboardPages/tailwind.svg';
import react from '../../assets/dashboardPages/react.svg';
import java from '../../assets/dashboardPages/java.svg';
import HeadSection from '../../components/HeadSection';

import cat from '../../assets/dashboardPages/cat.svg';
import catLogo from '../../assets/dashboardPages/catLogo.svg';
import catLogo2 from '../../assets/dashboardPages/catLogo2.svg';

const stackCards = [
  {img: tailwind , name:'Tailwind Css'},
  {img: react , name:'React Js'},
  {img: java , name:'Java Fx'},
];

const assetsCards = [
  {img: cat , name:'Cat.gif'},
  {img: catLogo , name:'Logo.svg'},
  {img: catLogo2 , name:'Cat.png'},
];

function StackAndAssets({page}) {

  return (

    <div className='stack__container'>
    <HeadSection title={page === 'stack' ? 'Stack' : 'Assets'} description='Generate production-grade code from Figma designs. Build stunning apps '/>

    <h3 className='font-bold text-[21px] mb-[32px]'>{page === 'stack' ? 'Development Services Used' : 'Media’s Files in use'}</h3>

   <div className='w-full flex md:flex-row flex-col items-center md:items-start'>
     {page === 'stack' && stackCards.map(card => <Card key={card.name} img={card.img} name={card.name}/>)}

     {page === 'assets' && assetsCards.map(card => <Card key={card.name} img={card.img} name={card.name}/>)}
   </div>
   </div>
  )
}

export default StackAndAssets;

const Card = ({img,name}) => {

  return(
    <div className='w-[100%] md:w-[28%] flex flex-col overflow-hidden md:mr-[32px] mb-[10px] md:mb-[0]'>
      <img src={img} alt=""/>
      <div className='border-r-[#DDE0E2] border-l-[#DDE0E2] border-b-[#DDE0E2] rounded-br-[8px] rounded-bl-[8px] border-[1px] w-full py-[15px] px-[20px]'>
        <h5 className='ml-[20px] font-bold text-[16px]'>{name}</h5>
      </div>
    </div>
  )
}
