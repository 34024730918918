import { useState } from "react";
import { pricingpageCtaClick, trackEvent } from "../analytics";
import cat from "../assets/detective-cat.png";
import { SectionWrapper } from "./SectionWrapper";

const AdditionalSection = () => {
  const [isMouseEnter, setisMouseEnter] = useState(false);
  return (
    <SectionWrapper background={"bg-white"}>
      <div
        className="bg-amber-200 flex justify-between pt-[50px] px-[80px] rounded-[15px] md:w-3/4 overflow-clip"
        onMouseEnter={() => setisMouseEnter(true)}
        onMouseLeave={() => setisMouseEnter(false)}
      >
        <div>
          <p className="font-bold text-[25px]">
            Ready to build UI faster than anybody else?
          </p>
          <button
            onClick={() => {
              trackEvent(pricingpageCtaClick, { button_id: "AdditionalSection", button_text: "Get Started" })
              window.open("/figma-plugin")
            }}
            type="button"
            className="mt-10 border border-violet-700 focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 text-white bg-violet-700 hover:bg-violet-800   font-medium rounded-md text-sm px-6 py-2.5 mb-2 mr-2 dark:bg-violet-600 dark:hover:bg-violet-700 dark:focus:ring-violet-900"
          >
            Get Started
          </button>
        </div>
        <div className="mt-[-50px]">
          <img
            src={cat}
            alt={cat}
            style={{ height: "271px" }}
            className={`${isMouseEnter ? "translate-y-10" : "translate-y-1/3"
              } duration-300 ease-in`}
          />
        </div>
      </div>
    </SectionWrapper>
  );
};

export default AdditionalSection;
