import { SectionWrapper } from "./SectionWrapper";
import secure from "../assets/secure.png";
export const SecureSection = () => {
  return (
    <SectionWrapper background={"bg-white"} justify={"between md:w-2/3"}>
      
      <div className="flex flex-col md:w-1/2 pr-[60px]" data-aos="fade-right">
        <div className="text-4xl font-bold text-black">
          Your designs and code are your own
        </div>
        <div className="text-lg my-[24px] text-black">
          We don&apos;t share your designs, code, or contact details with
          anyone. Period.
          <br />
          <br />
          We follow the latest security best practices to ensure the safety of
          your collaterals.
        </div>
      </div>
      <div className="flex flex-col md:w-1/2" data-aos="fade-left">
        <img src={secure} alt="vison"/>
      </div>
      
    </SectionWrapper>
  );
};
