import { useState } from "react";
import { SectionWrapper } from "./SectionWrapper";
import icon from "../assets/link.png";
import FigmaLogo from "../assets/figma-logo.png";
import { landingpageCtaClick, trackEvent } from "../analytics";
import step1 from "../assets/Step 1.png";
import step2 from "../assets/Step 2.png";
import step3 from "../assets/Step 3.png";
const steps = [
  {
    heading: "Sign up with CopyCat",
    description:
      "Sign up to CopyCat by clicking the ‘Get Started’ button.",
  },
  {
    heading: "Input Figma URL",
    description:
      "Copy paste the Figma design file URL into the dialogue box as shown.",
  },
  {
    heading: "Build UI Faster",
    description:
      "Generate code on your selection and export it to your environment.",
  },
];

const StepGifs = [{ img: step1, h: "720px", w: "450px" }, { img: step2, h: "720px", w: "450px" }, { img: step3, h: "720px", w: "450px" }]

export const StepsSection = () => {
  const [selectedStep, setSelectedStep] = useState(1)
  return (
    <SectionWrapper background={"bg-white"} justify="center">
      <div className="flex flex-col md:w-3/4 overflow-clip">
        <div
          className="mb-[18px] text-[20px] md:text-[38px] font-bold text-gray-900 text-center"
        >
          Speed up your React.js development by up to 35% today
        </div>
        <div className="flex flex-row flex-wrap justify-between">
          <div className="w-full md:w-1/3">
            {steps.map(({ heading, description }, index) => (
              <Step
                key={index}
                step={index + 1}
                heading={heading}
                description={description}
                selectStep={setSelectedStep}
              />
            ))}
          </div>
          <div className="hidden w-1/2 md:flex">
            <img src={StepGifs[selectedStep - 1].img} alt="gif" className={`mx-auto p-4`} loop="infinite" style={{ objectFit: "contain", maxWidth: StepGifs[selectedStep - 1].w, maxHeight: StepGifs[selectedStep - 1].h }} />
          </div>
        </div>
        <a
          href="https://www.notion.so/copycatdev/Documentation-live-3ff9652da99d4603bf7ab6f18c72e564"
          target="_blank"
          className="text-purple-600 flex flex-row"
          rel="noreferrer"
        >
          <p>Read our documentation for setup information</p>
          <img className="w-4 h-4 mt-[4px] ml-[4px]" src={icon} alt="" />
        </a>
        <div className="hidden md:block relative top-5 z-10 rounded-[50px] w-[35px] border-violet-500 border-2 bg-white h-[35px] px-[11px] py-[10px]">
          <img src={FigmaLogo} alt="" />
        </div>
        <button
          onClick={() => {
            trackEvent(landingpageCtaClick, { button_id: "StepsSection", button_text: " Get Started" })
            window.open("/figma-plugin")
          }}
          type="button"
          className="w-full mt-1 md:w-[150px] md:ml-4 border border-violet-700 focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 text-white bg-violet-700 hover:bg-violet-800   font-medium rounded-md text-sm px-6 py-2.5 mb-2 mr-2 dark:bg-violet-600 dark:hover:bg-violet-700 dark:focus:ring-violet-900"
        >
          Get Started
        </button>
      </div>
    </SectionWrapper>
  );
};

export const Step = ({ step, heading, description, selectStep }) => {
  const [isMouseEnter, setisMouseEnter] = useState(step === 1);
  return (

    <div className="flex flex-col w-full border-b-2 last:border-b-0"
      onMouseEnter={() => { setisMouseEnter(true); selectStep(step) }}
      onMouseLeave={() => setisMouseEnter(false)}
    >
      <div
        className={`flex flex-row py-[10px] font-bold ${isMouseEnter ? "text-violet-700" : "text-gray-900"
          } gap-4 items-center`}
      >
        <div className={`text-[20px] font-bold md:text-[48px]`}>{step}</div>
        <div className={`text-[20px] font-bold md:text-[24px]`}>{heading}</div>
      </div>
      {isMouseEnter && (
        <><p className="text-[16px] md:text-[20px] text-gray-900 py-[10px]">{description}</p>
          <div className="md:hidden flex w-full">
            <img src={StepGifs[step - 1].img} alt="gif" className="mx-auto p-4" loop="infinite" />
          </div>
        </>
      )}
    </div>
  );
};
