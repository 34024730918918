import React from 'react'
import googleCustomer from '../../../assets/product/googleCustomer.svg';
import hclCustomer from '../../../assets/product/hclCuatomer.svg';
import microsoftCustomer from '../../../assets/product/microsoftCustomer.svg';
import oracleCustomer from '../../../assets/product/oracleCustomer.svg';
import byteDance from '../../../assets/product/byteDance.svg';
import CustomerSlider from './CustomerSlider';
import './customerSlider.css';
import GrantVine from '../../../assets/product/Grant-Vine.svg';
import MatthiasCoutin from '../../../assets/product/Matthias-Coutin.svg';

const customers = [
{
    percentage:75,
    percentageTitle:'More Developer Productivity',
    comment:'We were able to quickly build React.js landing pages using CopyCat - within a quarter of the time it would have otherwise taken',
    avatar:GrantVine ,
    name:'Grant Vine' ,
    job:'CTO' ,
    company:'Finclusion Group',
    
},
{
    percentage:70,
    percentageTitle:'CopyCat does 70% of our work',
    comment:"CopyCat is very promising in our development workflow. I will give it an eight on ten. l started using CopyCat to analyze if the extension can help us to develop web applications using our framework. I have come to the conclusion that it does 70% of our work. We will continue to use the app in our workflow.",
    avatar:MatthiasCoutin ,
    name:'Matthias coutin' ,
    job:'Development Engineer' ,
    company:'Logicells'
},
];

function Customers() {
  return (
    <div className='bg-[#F9F6FF] py-[60px] md:py-[120px] px-[20px] md:px-[200px]'>
        <h1 className='text-[32px] md:text-[45px] text-center w-[100%] md:w-[50%] mx-auto font-[700] leading-[42px] md:leading-[61px] mb-[30px] text-[#131313]'>Our Awesome Customers</h1>
        

        <div className='flex items-center justify-between w-full py-[20px] overflow-x-scroll md:overflow-x-hidden'>
            <img src={oracleCustomer} alt="Oracle" className='mx-[10px] md:mx-[0]'/>
            <img src={microsoftCustomer} alt="Microsoft" className='mx-[10px] md:mx-[0]'/>
            <img src={hclCustomer} alt="HCL" className='mx-[10px] md:mx-[0]'/>
            <img src={googleCustomer} alt="Google" className='mx-[10px] md:mx-[0]'/>
            <img src={byteDance} alt="Byte Dance" className='mx-[10px] md:mx-[0]'/>
        </div>

        <CustomerSlider customers={customers}/>
    </div>
  )
}

export default Customers