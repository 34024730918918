import * as mixpanel from "mixpanel-browser";

export const landingpageVisit = "landingpage-visit";
export const pricingpageVisit = "pricingpage-visit";
export const landingpageCtaClick = "landingpage-cta-click";
export const signuppageCtaClick = "signuppage-cta-click";
export const pricingpageCtaClick = "pricingpage-cta-click";
export const signuppageVisit = "signuppage-visit";
export const planSelect = "plan-select";
export const carddetailspageVisit = "carddetailspage-visit";
export const freetrialStart = "freetrial-start";
export const setuppageVisit = "setuppage-visit";
export const leadCapture = "lead-capture";

export function initAnalytics() {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}

export function trackEvent(event, obj) {
  const eventProperties = { ...obj, source: "landingPage" };
  mixpanel.track(event, eventProperties);
}
