import { SectionWrapper } from "./SectionWrapper";
import HorizontalSeperator from "../assets/horizontal-seperator.png";
import Line from "../assets/vector-line.png";
import "./customAnimations.css";
import "./companyLogoAnimation.css";
export const BrandLogoSection = () => {
  return (
    <SectionWrapper background={"bg-amber-50"} justify="center">
      <div className="flex flex-col min-w-fit w-full overflow-clip">
        <div className="flex flex-row justify-center gap-10">
          <img
            className="my-auto"
            src={Line}
            alt="figma-to-react"
            width={240}
            style={{ height: "3px" }}
          />
          <img
            className="my-auto md:w-[62px] w-[25px]"
            src={HorizontalSeperator}
            alt="figma-to-react"
          />
          <img
            className="my-auto"
            src={Line}
            alt="figma-to-react"
            width={240}
            style={{ height: "3px" }}
          />
        </div>
        <div
          className="mt-[18px] text-[20px] md:text-[38px] font-bold text-gray-900 text-center"
        >
          Product teams in the fastest growing
          <br /> companies are using CopyCat
        </div>
        <div className="tech-slideshow mt-[24px] h-[27px] md:h-[100px]">
          <div className="mover-1 h-[27px] md:h-[100px] w-[900%]">
            {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17].map((value) => <img
              src={require(`../assets/companyLogos/logo (${value}).png`)}
              alt="companyLogos"
              className="h-[27px] md:h-[100px]"
              // height={100}
            />)}
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};
