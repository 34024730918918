import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import './dropdown.css';
import arrow from '../../../assets/arrow.svg';

function Dropdown({children = [],dropdownName = ''}) {
  const [OpenDropdown, setOpenDropdown] = useState(false);

  const toggleDropdown = () =>{
    setOpenDropdown((prev) => !prev);
  }

  return (
    <ul className={`drop-down ${OpenDropdown ? '' : 'closed'}`}>
      <div onClick={toggleDropdown}><span>{dropdownName}</span> <img src={arrow} className={OpenDropdown ? 'rotate-90' : 'rotate-0'} alt="" /></div>
        {
          children.map(item =>{
            return <li key={item.path}><NavLink to={item.path}>{item.name}</NavLink></li>
          })
        }
    </ul>
  )
}

export default Dropdown