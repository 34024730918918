import { useState } from "react";
import { landingpageCtaClick, trackEvent } from "../analytics";
import logo from "../assets/cat-withback.png";
import down from "../assets/chevron-down.svg";

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <nav className="bg-white border-gray-200 p-2 sm:px-4 fixed w-full z-20 shadow-sm">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        <div className="flex flex-wrap items-center">
          <div className="flex flex-col items-center">
            <a href="/" className="flex">
              <img src={logo} className="mr-3 h-6 sm:h-9" alt="Copycat Logo" />
              <span className="self-center text-xl font-bold whitespace-nowrap font-[Athletics]">
                Copycat
              </span>
            </a>
          </div>
          <div
            className={`flex flex-col md:items-center ${
              !isOpen ? "hidden" : ""
            } w-full md:block md:w-auto`}
            id="navbar-default"
          >
            <ul className="flex flex-col md:items-center align-middle md:justify-center pl-[48px] p-4 transition-all duration-300 ease-in-out md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white">
              <NavItem label={"Product"} href={"/product"} dropdown={false} />
              <NavItem
                label={"Community"}
                href={"/"}
                options={[
                  {
                    label: "Slack - Become a CopyCat",
                    href: "https://join.slack.com/t/copycat-community/shared_invite/zt-1dyboadba-BhqCaKiOlmKM~~wimunHmg",
                    target: "_blank",
                  },
                  {
                    label: "Follow us on Linkedin",
                    href: "https://www.linkedin.com/company/copycat-dev/",
                    target: "_blank",
                  },
                ]}
                dropdown={true}
              />
              <NavItem
                label={"Resources"}
                href={"/"}
                options={[
                  {
                    label: "Docs",
                    href: "/docs",
                  },
                  {
                    label: "Blog",
                    href: "/blog",
                  },
                ]}
                dropdown={true}
              />
              <NavItem
                label={"Manage Subscription"}
                href={
                  "https://subscription.stripe.copycat.dev/p/login/4gw7uVcnJfbIeSQ3cc"
                }
                dropdown={false}
              />
            </ul>
          </div>
        </div>

        <button
          onClick={() => setIsOpen(!isOpen)}
          data-collapse-toggle="navbar-default"
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
        <div
          className={`${!isOpen ? "hidden" : ""} w-full md:block md:w-auto`}
          id="navbar-default"
        >
          <ul className="flex flex-col p-2 mt-4 bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:bg-white">
            <li className="flex items-center justify-center">
              <button
                onClick={() => {
                  trackEvent(landingpageCtaClick, {
                    button_id: "Navbar",
                    button_text: " Get Started",
                  });
                  window.location.href = "/figma-plugin";
                }}
                type="button"
                className="border border-violet-700 focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 text-white bg-violet-700 hover:bg-violet-800   font-medium rounded-md text-sm px-6 py-2.5 mb-2 mr-2 dark:bg-violet-600 dark:hover:bg-violet-700 dark:focus:ring-violet-900"
              >
                Get Started
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export const NavItem = ({ label, href, dropdown, options, replace }) => {
  return (
    <li className="pb-1 flex flex-col pt-1 justify-center group bg-bottom bg-gradient-to-r from-violet-700 to-violet-700 bg-[length:0%_2px] bg-no-repeat group-hover:bg-[length:100%_2px] transition-all duration-500 ease-out">
      {dropdown ? (
        <div className="flex-row items-center">
          <button className="peer items-center inline-flex py-1 pr-4 pl-3 text-gray-900 bg-transparent md:p-0">
            <span className="text-base hover:text-violet-700 ">{label}</span>
            <img
              src={down}
              alt=""
              className="ml-[6px] pt-1"
              style={{ height: "18px" }}
            />
          </button>

          <div className="absolute hidden peer-hover:flex hover:flex flex-col bg-white drop-shadow-lg min-w-[234px]">
            {options.map((option) => (
              <a
                {...option}
                key={option.label}
                className="px-[28px] py-[10px] border-[1px] hover:bg-purple-50 hover:text-violet-700 hover:border-violet-700"
                rel="noreferrer"
              >
                {option.label}
              </a>
            ))}
          </div>
        </div>
      ) : (
        <a
          href={href}
          className=" block py-1 pr-4 pl-3 text-gray-900 bg-transparent md:p-0 "
          aria-current="page"
        >
          <span className="text-base hover:text-violet-700 ">{label}</span>
        </a>
      )}
    </li>
  );
};
