import AOS from "aos";
import "aos/dist/aos.css";
import DocumentMeta from "react-document-meta";
import { Route, Routes } from "react-router-dom";
import { initAnalytics } from "./analytics";
import { Footer } from "./components/Footer";
import { Navbar } from "./components/Navbar";
import { Home } from "./views/Home";
import { Pricing } from "./views/Pricing";
import TagManager from "react-gtm-module";
import DashboardLayout from "./views/Dashboard";
import DesignsHome from "./views/Designs/Home";
import './App.css';
import ComponentsButtons from "./views/ComponentPages/Buttons";
import LayoutCodesHome from "./views/LayoutCodes/Home";
import StackAndAssets from "./views/StackAndAssets";
import Product from "./views/Product/Product";

AOS.init();
function App() {
  //Google Tag Manager -- Starts
  const tagManagerArgs = {
    gtmId: "GTM-M9X5GLL",
  };
  TagManager.initialize(tagManagerArgs);
  //Google Tag Manager -- Ends
  initAnalytics();
  const yandex_meta = {
    meta:{
      name: {
        'yandex-verification': '300bb6188362a9e0'
      }
    }
  }

  return (
    <div className="justify-center">
      <DocumentMeta {...yandex_meta}/>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing2" element={<Pricing />} />
        <Route path="/product" element={<Product />} />

        <Route path='/dashboard' element={<DashboardLayout />}>
          <Route path="/dashboard/designs/home" element={<DesignsHome/>} />
          <Route path="/dashboard/designs/product" element={<DesignsHome/>} />
          <Route path="/dashboard/designs/pricing" element={<DesignsHome/>} />
          {/* /dashboard/designs/product , /dashboard/designs/pricing page designs are not ready! */}

          <Route path="/dashboard/components/buttons" element={<ComponentsButtons/>} />
          <Route path="/dashboard/components/navbar" element={<ComponentsButtons/>} />
          <Route path="/dashboard/components/footer" element={<ComponentsButtons/>} />
          {/* /dashboard/components/navbar , /dashboard/components/footer page designs are not ready! */}

          <Route path="/dashboard/layout/home" element={<LayoutCodesHome/>} />
          <Route path="/dashboard/layout/product" element={<LayoutCodesHome/>} />
          <Route path="/dashboard/layout/pricing" element={<LayoutCodesHome/>} />
          {/* /dashboard/layout/product, /dashboard/layout/pricing page designs are not ready! */}

          <Route path="/dashboard/stack" element={<StackAndAssets page='stack'/>} />
          <Route path="/dashboard/assets" element={<StackAndAssets page='assets' />} />

        </Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
