import React from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import './usageTabs.css';


function UsageTabs({tabs}) {
  return (
    <Tabs>
    <TabList >
        {tabs.map(tab =>{
            return <Tab key={tab.title}>{tab.title}</Tab>
        })}
    </TabList>

     {
        tabs.map(tab =>{
            return (
                <TabPanel key={tab.title} className='mx-auto block w-full md:w-[70%]'>
                    <TabPanelContent  content={tab}/>
                </TabPanel>
            )
        })
     }

  </Tabs>
  )
}

export default UsageTabs;

const TabPanelContent = ({content}) =>{
    return (
        <div className='w-full flex flex-col md:flex-row pt-[25px] md:pt-[50px] items-start md:items-center'>
                <div className='w-full md:w-[65%] px-[0] md:px-[50px] flex flex-col items-start justify-start'>
                    <div className='flex items-start md:items-center justify-start mb-[20px]'>
                        <img src={content.icon} alt="" className='mr-[.5rem] w-[35px] h-[35px]'/>
                        <span className='text-[#131313] text-[22px] font-[700px]'>{content.title}</span>
                    </div>
                    <p className='text-[#131313] text-[18px]'>{content.description}</p>
                </div>
                <div className='w-full md:w-[35%] rounded-[8px] h-[200px] md:h-[220px] mt-[20px] md:mt-[0]'>
                    <img src={content.image} alt="" />
                </div>
        </div>
    )
}