export const SectionWrapper = ({ children, background, justify, position }) => {
  return (
    <div className={`${position} ${background} px-2 sm:px-4`}>
      <div
        className={`container flex flex-wrap justify-center justify-${justify} items-center mx-auto py-[36px] md:py-[60px]`}
      >
        {children}
      </div>
    </div>
  );
};
