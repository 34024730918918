import React from 'react';
import copycatProductHead from '../../../assets/product/copycatProductHead.svg';
import figmaLogo from '../../../assets/figma-logo.png';
import reactLogo from '../../../assets/react-blue.svg';
import arrowSmall from '../../../assets/arrow-small.png';


function ProductHead() {
  return (
    <div className='product-head__container flex flex-col md:justify-between md:flex-row px-[20px] py-[25px] md:px-[100px] md:py-[120px] bg-[#FDD0091A]'>
        <div className='w-full md:w-[50%]'>
        <h1 className='text-[38px] md:text-[48px] font-[700] leading-[55px] md:leading-[61px] mb-[20px] text-[#000000]'>Generate scaffolding code directly from Figma designs</h1>
        <p className='text-[21px] mb-[0] md:mb-[40px]'>Reduce sprint delays and design inconsistencies.</p>
        <div>
        
          <button
                type="button"
                className="mt-10 border border-700 border-[#FDD009] focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 text-[#000000] bg-700 bg-[#FDD009] hover:bg-800   font-medium rounded-md text-sm px-6 py-2.5 mb-2 dark:bg-600 dark:bg-[#FDD009] dark:hover:bg-[#FDD009]-700 dark:focus:ring-[#FDD009]-900 mr-[20px] ml-[10px]" 
          >
            Get Started
          </button>
          <button
            type="button"
            className="mt-10 border border-violet-700 focus:outline-none shadow-2d hover:shadow-1d shadow-black-700/50 text-white bg-violet-700 hover:bg-violet-800   font-medium rounded-md text-sm px-6 py-2.5 mb-2 mr-2 dark:bg-violet-600 dark:hover:bg-violet-700 dark:focus:ring-violet-900"
          >
            Join Community
          </button>
        </div>
    </div>

      <div className='w-full mt-[100px] md:mt-[0] md:w-[30%]  h-[450px] relative'>
        <img src={copycatProductHead} alt="" />
        <div className='absolute top-[-60px] md:top-[-50px] left-[0] md:left-[-50px] border-[#00000033] border-[1px] rounded-[17px] py-[10px] px-[10px] flex items-center justify-around w-[100%] md:w-[150px]'>
          <img src={figmaLogo} alt="" className='w-[20px] h-[27px] mx-[5px]'/>
          <img src={arrowSmall} alt=">" className='w-[16px] h-[16px] mx-[5px]'/>
          <img src={reactLogo} alt="" className='w-[27px] h-[27px] mx-[5px]'/>
        </div>
      </div>
    </div>
  )
}

export default ProductHead