import brahmnoor from "../assets/brhamnoor.png";
import rahul from "../assets/rahul.png";
import avatar from "../assets/avatar.png";
import { SectionWrapper } from "./SectionWrapper";
const comments = [
  {
    name: "Brahmnoor Chawla",
    avatar: brahmnoor,
    designation: "Software Engineer at Google",
    comment: {
      heading: "Built a stable web app super-fast.",
      subheading:
        "CopyCat helped us build a production-grade web app and ship it quickly.",
    },
  },
  {
    name: "Rahul Chacko",
    avatar: rahul,
    designation: "Product Lead at Qyuki Digital Media",
    comment: {
      heading: "Minimal refactoring required.",
      subheading:
        "The code generated by CopyCat is usable in my day-to-day work. The code generated is humanlike and readable, making editing a breeze.",
    },
  },
  // {
  //   name: "Roman Dillet",
  //   designation: "Senior Developer",
  //   comment: {
  //     heading: "Minimal refactoring required",
  //     subheading:
  //       "The code generated by CopyCat is usable in my day-to-day work. The code generated is humanlike and readable, making editing a breeze.",
  //   },
  // },
  {
    name: "Ilango Rajagopal",
    avatar: avatar,
    designation: "CEO @ Easyauth",
    comment: {
      heading: "Saves 22% time every sprint.",
      subheading:
        "CopyCat takes care of all the boiler-plate code files, assets, and components, saving 22% of my time every sprint.",
    },
  },
];

export const CommentsSection = () => {
  return (
    <SectionWrapper background={"bg-white"} justify="between">
      <div className="flex flex-col w-full">
        <div
          className="text-[38px] font-bold text-gray-900 text-center"
          data-aos="fade-up"
        >
          See what our customers say{" "}
        </div>

        <div
          className="flex flex-wrap justify-evenly mt-[36px]"
          data-aos="fade-in"
        >
          {comments.map((comment, index) => (
            <Comment key={index} comment={comment} index={index} />
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
};

const Comment = ({ comment, index }) => {
  return (
    <div
      className={`border-[1px] mb-[36px] border-violet-700 shadow-md px-10 py-[50px] rounded-md bg-opacity-60 bg-purple-50 flex flex-col flex-wrap justify-between gap-4 w-full md:w-1/4 md:min-w-[377px] duration-[${
        1000 * (index + 1)
      }ms]`}
      data-aos="fade-right"
    >
      <div className="text-[24px] font-bold min-h-1/3" data-aos="fade-in">
        {comment.comment.heading}
      </div>
      <div className="text-[16px] font-normal min-h-1/3" data-aos="fade-in">
        {comment.comment.subheading}
      </div>

      <div
        className="flex flex-row justify-between min-h-1/3"
        data-aos="fade-up"
      >
        <div className="flex flex-col w-1/4 my-auto">
          <img src={comment.avatar} alt="avatar" className="rounded-full" />
        </div>
        <div className="flex flex-col w-2/3 my-auto">
          <div className="text-xl text-gray-900 font-bold">{comment.name}</div>
          <div className="text-medium text-gray-900">{comment.designation}</div>
        </div>
      </div>
    </div>
  );
};
