import React from 'react'
import arrowTop from '../../../assets/product/arrowTop.svg';
import qoute from '../../../assets/product/qoute.svg';

function CustomerSlideCard({percentage,comment,avatar,name,job,company,percentageTitle}) {
  return (
    <div className='bg-[#FFFFFF] flex flex-col md:flex-row  w-[100%] h-[100%] rounded-[16px] shadow-[0_0__5px_rgba(0,0,0,0.1)] p-[32px]'>

        <div className='bg-gradient-to-b from-[#A347EC] to-[#6930CA] flex flex-col items-center justify-center rounded-[16px] mb-[20px] md:mb-[0] md:mr-[45px] py-[20px] md:py-[70px] w-full md:w-[40%] px-[15px] md:px-[65px]'>
            <div className='flex items-center '>
                <img src={arrowTop} alt="" className='mr-[15px] w-[20px] h-[30px] md:w-[29px] md:h-[48px] text-[#ffffff]'/>
                <span className='text-[42px] md:text-[60px] font-[700] text-[#ffffff]'>{percentage}%</span>
            </div>
            <div className='flex items-center md:items-start'>
                <span className='text-center md:text-start text-[#ffffff] text-[20px]'>{percentageTitle}</span>
            </div>
        </div>
        <div className='flex flex-col w-full md:w-[50%] items-start justify-between'>
            <img src={qoute} alt="" className='w-[45px] h-[45px] md:w-[60px] md:h-[60px]'/>
            <p className='overflow-scroll text-[20px] text-[#292D32] max-h-[215px] pb-[20px] md:pb-[10px]'>{comment}</p>

            <div className='flex w-full'>
                <img src={avatar} alt="" className='w-[60px] h-[60px] md:w-[72px] md:h-[72px] mr-[20px]'/>
                <div className='flex flex-col'>
                    <span className='font-[700] text-[14px] md:text-[16px] text-[#242424] mb-[2px]'>{name}</span>
                    <span className='mb-[1px] text-[14px] md:text-[16px] font-[400] text-[#292D32]'>{job}</span>
                    <span className='text-[14px] md:text-[16px] font-[400] text-[#292D32] opacity-[0.4]'>{company}</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CustomerSlideCard