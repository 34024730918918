import React from "react";
import { SectionWrapper } from "./SectionWrapper";
import Question from "./Question";
import faqs from "./faqs.json";

function FAQSection() {
  return (
    <SectionWrapper background={"bg-white"}>
      <div className="flex flex-col w-full md:text-center">
        <p className="text-[20px] md:text-[38px] text-center font-bold mb-4">
          Frequently asked questions
        </p>
        <div className="w-full md:w-1/2 ml-auto mr-auto flex flex-col">
          {faqs.map((faq, index) => (
            <Question
              key={index}
              question={faq.question}
              answer={<Answer answer={faq.answer} answer2={faq.answer2} />}
            />
          ))}
        </div>
      </div>
    </SectionWrapper>
  );
}

export const Answer = ({ answer, answer2 }) => (
  <>
    <div className="font-light text-gray-600">{answer}</div>
    <br/>
    <div className="font-light text-gray-600">{answer2}</div>
  </>
);

export default FAQSection;
