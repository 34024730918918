import React, { useState } from "react";
import up from "../assets/chevron-up.svg";
import down from "../assets/chevron-down.svg";

function Question({ question, answer }) {
  const [open, setOpen] = useState(false);
  return (
    <div
      className="flex flex-col font-bold border-b-2 border-gray last:border-b-0"
      onClick={() => setOpen(!open)}
    >
      <div className="justify-between flex items-center text-left">
        <p className="py-[20px] md:pt-[34px] md:pb-[24px] text-[16px] md:text-[20px] ">{question}</p>
        {!open ? (
          <img src={down} alt="" style={{ width: "16px" }} />
        ) : (
          <img src={up} alt="" style={{ width: "16px" }} />
        )}
      </div>
      {open && <p className="text-left mb-[24px]">{answer}</p>}
    </div>
  );
}

export default Question;
