import React from 'react'
import HeadSection from '../../../components/HeadSection'
import copycatHome from '../../../assets/dashboardPages/copycatHome.svg';
import figmaVector from '../../../assets/dashboardPages/figmaVector.svg';

function DesignsHome() {
  return (
    <div className='designs-home__container'>
     <HeadSection title='Home Page' description='Generate production-grade code from Figma designs. Build stunning apps'/>

     <h3 className='font-bold text-[21px] mb-[32px]'>Design File</h3>

    <div className='w-full flex flex-col overflow-hidden'>
      <img src={copycatHome} alt=""/>
      <div className='border-r-[#DDE0E2] border-l-[#DDE0E2] border-b-[#DDE0E2] rounded-br-[8px] rounded-bl-[8px] border-[1px] w-full flex flex-row items-center justify-start py-[15px] px-[20px]'>
        <img src={figmaVector} alt="" className='' />
        <h5 className='ml-[20px] font-bold text-[16px]'>Copycat Home</h5>
        <span className='ml-[12px] text-[#5F5F5F] text-[12px]'>Edited 2 months ago</span>
      </div>
    </div>
    </div>
  )
}

export default DesignsHome