import gif from "../assets/cat_anim.gif";
import Line from "../assets/vector-line.png";
import { SectionWrapper } from "./SectionWrapper";
export const VisionSection = () => {
  return (
    <SectionWrapper background={"bg-amber-50"} justify="center">
      <div className="flex flex-col md:w-2/3 overflow-clip">
        <div className="flex flex-row justify-center md:gap-10 -mt-[36px]">
          <img
            className="my-auto"
            src={Line}
            alt="figma-to-react"
            
            
            width={200}
            style={{ height: "3px" }}
          />
          <img
            src={gif}
            alt="figma-to-react"
            
            width={140}
          />
          <img
            className="my-auto"
            src={Line}
            alt="figma-to-react"
            
            
            width={200}
            style={{ height: "3px" }}
          />
        </div>
        <div
          className="mt-[18px] text-[38px] font-bold text-gray-900 text-center"
          
        >
          Revolutionizing the designer-developer handoff
        </div>
        <div
          className="mt-[18px] text-[20px] text-gray-900 text-center"
          
        >
          Create masterpieces with minimal back and forth. Developing
          pixel-perfect U.I. takes too much time in this day and age of A.I. We
          want to remove the pain of having to write redundant boilerplate,
          layout, and atomic code so that you can focus on building truly
          remarkable apps faster.
        </div>
      </div>
    </SectionWrapper>
  );
};
