import React from 'react'
import {Swiper,SwiperSlide} from 'swiper/react'
import { Keyboard, Mousewheel, Navigation,Pagination } from "swiper";
import CustomerSlideCard from './CustomerSlideCard';
import './customerSlider.css'

function CustomerSlider({customers}) {
  return (
    <Swiper         
    cssMode={true}
    navigation={true}
    pagination={true}
    mousewheel={true}
    keyboard={true}
    modules={[Navigation, Pagination, Mousewheel, Keyboard]} 
    className="mySwiper max-w-[780px] h-auto md:h-[440px]">

        {customers.map((customer,index) => {
            return (
                <SwiperSlide key={index}>
                    <CustomerSlideCard 
                    percentage={customer.percentage} 
                    percentageTitle={customer.percentageTitle} 
                    comment={customer.comment} 
                    avatar={customer.avatar} 
                    name={customer.name} 
                    job={customer.job} 
                    company={customer.company}
                    />
                </SwiperSlide>
            )
        })}
    

  </Swiper>
  )
}

export default CustomerSlider